/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, CardActionArea, CardContent, Card, Grid, Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Check, Clear } from '@material-ui/icons';
import { addUnitTimers, sec2time } from '../../../helpers/utils';
import moduleServices from '../../../redux/services/moduleServices';
import LinearProgressWithLabelTime from '../../shared/LinearProgressWithLabelTime';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    width: '100%',
    maxHeight: 50,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subroot: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const TotalTimeWidget = ({
  data, _currentGroup, authData, group,
}) => {
  const classes = useStyles();

  const [totalTimePercent, setTotalTimePercent] = useState(null);
  const [hundredPercentTime, sethundredPercentTime] = useState(null);
  const [hundredPercentTimePercentage, setHundredPercentTimePercentage] = useState(null);
  const [elearningDurationValue, setElearningDurationValue] = useState(1);
  const [elearningDurationTarget, setElearningDurationTarget] = useState(0);
  const [timeFormatedState, setTimeFormatedState] = useState(null);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [totalTimeFormatedState, setTotalTimeFormatedState] = useState(null);

  useEffect(() => {
    const currentGroup = Cookies.get('currentGroup');
    setTotalTimePercent(null);
    setTimeFormatedState(null);
    setTotalTimeFormatedState(null);
    if (currentGroup && data[currentGroup]) {
      moduleServices.getTracker(authData._id, data[currentGroup]._id)
        .then(
          (res) => {
            let { elearningDuration } = data[currentGroup].elearning;
            elearningDuration *= (group.data[currentGroup].elearning.elearningDurationTarget ? group.data[currentGroup].elearning.elearningDurationTarget / 100 : 0);
            setElearningDurationValue(elearningDuration);
            setElearningDurationTarget(data[currentGroup].elearning.elearningDurationTarget);
            let timeInSecs = addUnitTimers(res.units);
            moduleServices.getIdleTracker(authData._id, data[currentGroup]._id)
              .then((_res) => {
                if (_res.units) {
                  _res.units.forEach((unit) => {
                    if (unit.unit === data[currentGroup]._id) {
                      timeInSecs += unit.time;
                    }
                  });
                }
                const percent = elearningDuration ? ((timeInSecs / 60) / elearningDuration) * 100 : 100;
                sethundredPercentTime(sec2time(data[currentGroup].elearning.elearningDuration * 60));
                const percentTotal = elearningDuration ? ((timeInSecs / 60) / data[currentGroup].elearning.elearningDuration) * 100 : 100;
                setHundredPercentTimePercentage(percentTotal > 100 ? 100 : percentTotal);
                const timeFormated = sec2time(timeInSecs);
                const totalTimeInHour = sec2time(elearningDuration * 60);
                setTotalTimePercent(percent > 100 ? 100 : percent);
                setTimeFormatedState(timeFormated);
                setTotalTimeFormatedState(totalTimeInHour);
              })
              .catch(() => {
                setErrorSnackBar({ code: 1401, message: 'Missing idle tracker.', severity: 'warning' });
                const percent = elearningDuration ? ((timeInSecs / 60) / elearningDuration) * 100 : 100;
                sethundredPercentTime(sec2time(data[currentGroup].elearning.elearningDuration * 60));
                const percentTotal = elearningDuration ? ((timeInSecs / 60) / data[currentGroup].elearning.elearningDuration) * 100 : 100;
                setHundredPercentTimePercentage(percentTotal > 100 ? 100 : percentTotal);
                const timeFormated = sec2time(timeInSecs);
                const totalTimeInHour = sec2time(elearningDuration * 60);
                setTotalTimePercent(percent > 100 ? 100 : percent);
                setTimeFormatedState(timeFormated);
                setTotalTimeFormatedState(totalTimeInHour);
              });
          },
        );
    }
  }, [_currentGroup, Cookies.get('currentGroup')]);

  return (
    <Card
      style={{
        height: '100%',
        width: '100%',
        padding: 0,
      }}
    >
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <CardContent
        style={{
          height: '100%',
          width: '100%',
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyItems: 'center',
        }}
      >
        <CardActionArea
          component={Link}
          to={{
            pathname: '/elearning/results',
          }}
          className={classes.subroot}
        >
          <Grid item md={12} className={classes.cardHeader}>
            <Typography variant="body1" style={{ marginLeft: '20px', padding: 5, fontWeight: 'bold' }}>
              Check your progress
            </Typography>
          </Grid>
          {
            elearningDurationValue > 0
              ? (
                <div style={{
                  width: '90%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-around',
                  paddingTop: 20,
                  paddingBottom: 20,
                  height: '100%',
                }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      marginBottom: '10px',
                      gap: '10px',
                    }}
                  >
                    <Typography variant="body1" color="textPrimary">
                      Total connection time :
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {`${timeFormatedState === null || totalTimeFormatedState === null ? 'Loading...' : `${timeFormatedState} / ${hundredPercentTime}`}`}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      marginBottom: '10px',
                      gap: '10px',
                    }}
                  >
                    <Typography variant="body1" color="textPrimary">
                      Objective :
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {`${elearningDurationTarget}% (${totalTimeFormatedState})`}
                    </Typography>
                    {totalTimePercent === 100 || totalTimePercent === '100.0' ? <Check htmlColor="#66DD66" /> : <Clear htmlColor="#FF6C53" />}
                  </div>
                  {
                    timeFormatedState === null || totalTimeFormatedState === null || totalTimePercent === null
                      ? 'n/a'
                      : <LinearProgressWithLabelTime value={hundredPercentTimePercentage} objective={totalTimePercent} />
                  }
                </div>
              )
              : (
                <div style={{
                  width: '90%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  paddingTop: 20,
                  paddingBottom: 20,
                  height: '100%',
                }}
                >
                  <Typography variant="body1" color="textPrimary" style={{ paddingBottom: 10 }}>
                    {`Total connection time : ${timeFormatedState === null ? 'Loading...' : timeFormatedState}`}
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    No minimum required.
                  </Typography>
                </div>
              )
          }
        </CardActionArea>
      </CardContent>
    </Card>
  );
};

TotalTimeWidget.propTypes = {
  _currentGroup: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  authData: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
};

function mapState(state) {
  const { data } = state.group;
  const { authData } = state.auth;
  const { currentGroup } = state.ui;
  const { group } = state;
  return {
    data, authData, _currentGroup: currentGroup, group,
  };
}

export default connect(mapState, {})(TotalTimeWidget);
